.house {
  height: 30vw;
  max-height: 28vh;
  width: 24vw;
  z-index: 10;
  margin-left: 15vw;
}

.house-lot {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

.house-roof {
  width: 0;
  height: 0;
  border-left: 12vw solid transparent;
  border-right: 12vw solid transparent;
  border-bottom-width: 8vw;
  border-bottom-style: solid;
}

.house-secondFloor, .house-firstFloor {
  height: 100%;
  width: 60%;
  margin-left: 10%;
  margin-right: 10%;
  border-left-width: 1px;
  border-left-style: solid;
  border-right-width: 1px;
  border-right-style: solid;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 2vw;
}

.house-door {
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  margin: 5% 10% 0;
  width: 3vw;
  height: 7vw;
}