.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 100;
}

.overlay-headline {
  align-self: flex-start;
  margin-left: 5vw;
  margin-top: 3vh;
}