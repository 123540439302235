.window {
  background-color: rgb(95, 53, 34);
  margin: 5% 0 10%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1px;
  padding: 3px;
  width: 2.5vw;
  height: 5vw;
}

.window-pane {
  background-color: yellow;
}