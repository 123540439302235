.ground {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.ground-grass {
  height: 20vh;
}
