.sun-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.sun-pivotPoint {
  transform: translate(20vw, 25vh);
}

.sun-sun, .sun-moon {
  height: 0;
  width: 9vw;
  padding-top: 100%;
  border-radius: 50%;
  z-index: 1;
}

.sun-sun {
  background-color: #ffff94;
}

.sun-moon {
  background-color: rgba(0, 0, 0, 0);
  background-image: radial-gradient(
    circle at 15% 15%,
    rgba(0, 0, 0, 0) 0,
    rgba(0, 0, 0, 0) 50%,
    rgb(234, 250, 255) 80%
  );
}
